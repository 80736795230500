import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';

import { RequestOptions, RequestResponse } from './interfaces/request.interface';

import { environment } from '@environments/environment';
import { Observable, catchError, map, throwError } from 'rxjs';
import { Router } from '@angular/router';

const headers= new HttpHeaders({
  'Accept': 'application/json'
});

@Injectable({
  providedIn: 'root'
})
export class HttpRequestService {
  protected readonly http = inject(HttpClient);
  protected readonly api = environment.URL;
  protected readonly router = inject(Router);

  public get<T>(endpoint: string, options?: RequestOptions): Observable<T> {
    return this.http.get<T>(`${this.api}${endpoint}`, options)
                    .pipe( map(res => {
                      const code = (res as RequestResponse).code

                      if( code == 401 ){
                        // this.logout();
                      }

                      return res
                    }));
  }

  public getFile(endpoint: string): Observable<Blob> {
    return this.http.get(`${this.api}${endpoint}`, {responseType: 'blob'});
  }

  public post<T>(endpoint: string, body: any): Observable<T> {
    return this.http.post<any>(`${this.api}${endpoint}`, body, {'headers': headers})
    .pipe(
      map(res => {
        return res
    }),
    catchError(error => {
      console.log(error);
      const code = error.status;
      const err = error.error.detail.message ? '' : error.error.detail;
      console.log(code, err)
      throw {code, error: err};
    })
  );
  }

  public put<T>(endpoint: string, body: any): Observable<T> {
    return this.http.put<any>(`${this.api}${endpoint}`, body, {'headers': headers})
      .pipe(
        map(res => {
        return res
      }),
      catchError(error => {
        console.log(error);
        const code = error.status;
        const err = error.error.detail.message ? '' : error.error.detail;
        console.log(code, err)
        throw {code, error: err};
      })
    );
  }

}
